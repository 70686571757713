

















import { InputSetups } from '@/mixins/input-setups'
import ChipValueSettingView from "./components/ChipValueSettingView.vue";
import { SuperListCondition } from "./SuperListCondition";

import TypedBlackWhiteListInput from 'piramis-base-components/src/components/Pi/fields/TypedBlackWhiteListInput/TypedBlackWhiteListInput.vue'
import { PossibleChipTypeInfo } from "piramis-base-components/src/components/TypedChips/typedChips.types";

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    TypedBlackWhiteListInput
  }
})
export default class SuperListConditionView extends Mixins<InputSetups>(InputSetups) {
  @VModel() model!: SuperListCondition

  FACE_CONTROL_TYPES: PossibleChipTypeInfo = {
    'Contains': {
      isDefault: true,
      label: this.$t('face_control_list_mode_contains').toString(),
      prefix: 'c:',
      color: 'green',
      view: ChipValueSettingView,
      typeKey: 'mode'
    },
    'Equal': {
      label: this.$t('face_control_list_mode_equal').toString(),
      prefix: 'e:',
      color: 'blue',
      view: ChipValueSettingView,
      typeKey: 'mode'
    },
    'Start': {
      label: this.$t('face_control_list_mode_start').toString(),
      prefix: 's:',
      color: 'orange',
      view: ChipValueSettingView,
      typeKey: 'mode'
    },
    'End': {
      label: this.$t('face_control_list_mode_end').toString(),
      prefix: 'e:',
      color: 'red',
      view: ChipValueSettingView,
      typeKey: 'mode'
    },
    'RegEx': {
      label: this.$t('face_control_list_mode_regex').toString(),
      prefix: 'rx:',
      color: 'cyan',
      view: ChipValueSettingView,
      typeKey: 'mode'
    }
  }

}
