var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!!_vm.localValue)?_c('div',[(_vm.action === 'edit')?_c('text-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'localValue',
        'validation': 'required',
      },
    }}}):_vm._e(),(_vm.action === 'new')?_c('chips-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'localValue',
        'validation': 'required',
        'supportButtons': false
      }
    }}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }